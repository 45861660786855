@use "sass:math";
@import url("~reset-css");

@font-face {
  font-family: "BeausiteClassic";
  src: url("/fonts/beausite-classic/BeausiteClassicWeb-Regular.woff2")
      format("woff2"),
    url("/fonts/beausite-classic/BeausiteClassicWeb-Regular.woff")
      format("woff");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "BeausiteClassic";
  src: url("/fonts/beausite-classic/BeausiteClassicWeb-Bold.woff2")
      format("woff2"),
    url("/fonts/beausite-classic/BeausiteClassicWeb-Bold.woff") format("woff");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "BeausiteClassic";
  src: url("/fonts/beausite-classic/BeausiteClassicWeb-Medium.woff2")
      format("woff2"),
    url("/fonts/beausite-classic/BeausiteClassicWeb-Medium.woff") format("woff");
  font-weight: 600;
  font-display: swap;
}

// colors
$base-font-color: #323232;
$black: #000;
$blue-dark: #190c4e;
$blue: #21125e;
$gray-dark: #7d7d7d;
$gray-light: #f6f6f6;
$gray: #c4c4c4;
$green: #3ed256;
$rose: #fe6e76;
$accentBlue-dark: #55c4b3;
$accentBlue-light: #f1f5f5;
$accentBlue: #51e9d2;
$white: #fff;
$blue-overlay: rgba($blue, 0.15);
$autofillColor: rgb(250, 255, 189);
$autofillColorSelected: rgb(232, 240, 254);
$darkGreen: #06847b;

// typography
$base-font-family: BeausiteClassic;
$base-font-size: 1rem; // 16px
$small-font-size: 0.875rem; // 14px
$base-line-height: 1.25rem; // 20px
$h1-font-size: 3.75rem; // 60px
$h1-line-height: 3.75rem;
$h1-font-family: BeausiteClassic;

$h2-font-size: 2.5rem; // 40px
$h2-line-height: 2.75rem;
$h2-font-family: BeausiteClassic;

$h3-font-size: 1.9rem; // 30pt
$h3-line-height: 1.9rem;
$h3-font-family: BeausiteClassic;

$h4-font-size: 1.125rem; // 18px
$bold-font-weight: 700;
$extra-bold-font-weight: 900;

// spacing
$spacer: 1rem; // 16px

// grid
$container-width: 1140px;

// button
$button-font-size: 1.125rem;
$button-line-height: $base-line-height;
$button-box-shadow: -5px 5px 14px 0px rgba(0, 0, 0, 0.2);
$button-box-shadow-clicked: -3px 3px 14px 0px rgba(129, 67, 67, 0.2);
$button-padding: 0.9rem 3.7rem;
$button-padding-small-screen: 0.9rem 1rem;
$button-transition: 0.3s;
$button-transform: skew(45deg);
$button-content-transform: skew(-45deg);
$button-text-transform: uppercase;
$button-font-weight: $bold-font-weight;

// input
$label-font-size: 0.75rem; // 12px
$label-color: $gray-dark;
$select-menu-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);

// message
$message-width: 25rem;
$message-padding: 1.5rem;
$message-background-color: $white;
$message-shadow: 0px 6px 15px 3px rgba(0, 0, 0, 0.25);
$message-right-position: $spacer;
$message-botttom-position: $spacer;
$message-success-color: $green;
$message-error-color: $rose;
$message-neutral-color: $accentBlue-dark;
$message-border-left: 0.3rem solid $message-neutral-color;
$message-title-transform: uppercase;
$message-title-weight: $bold-font-weight;
$message-title-margin: 0 $spacer * 1.5 0 0;
$message-content-margin: $spacer 0 0;

// product item
$product-item-title-text-transform: uppercase;
$product-item-title-font-weight: $bold-font-weight;
$product-item-title-margin: math.div($spacer, 2) 0 0;
$product-item-title-height: 2.5rem;
$product-item-category-color: $gray;
$product-item-price-font-weight: $bold-font-weight;
$product-item-price-margin: $spacer 0 0;

// breakpoints
$medium-screen: 992px;
$small-screen: 540px;

// carousel
$carousel-control-padding: 0.2rem 0.5rem;
$carousel-control-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

// container
$container-width: 1140px;

// modal
$modal-width: 555px;
$modal-min-height: 455px;

:export {
  mediumScreen: $medium-screen;
  smallScreen: $small-screen;
}

.u- {
  &lead {
    font-size: $h3-font-size;
    font-weight: $bold-font-weight;
    line-height: normal;
    padding-bottom: 10px;

    &--bold {
      color: $gray-dark;
      font-weight: $extra-bold-font-weight;
    }
  }

  &uppercase {
    text-transform: uppercase;
  }

  &link {
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
  }
}

.c- {
  &option {
    cursor: pointer;
    padding: $spacer $spacer $spacer $spacer * 2.5;
    position: relative;

    label {
      cursor: pointer;

      &::before {
        display: block;
        position: absolute;
        content: "";
        border-radius: 100%;
        height: 0.5rem;
        width: 0.5rem;
        top: $spacer * 1.25;
        left: $spacer;
        margin: auto;
        box-shadow: 0 0 0 1px $blue;
        border: 2px solid $white;
        background-color: $white;
      }
    }

    &--selected {
      label::before {
        background-color: $blue;
      }
    }

    &--disabled {
      label::before {
        background-color: rgba($gray, 0.5);
        box-shadow: 0 0 0 1px $gray;
      }
    }

    input {
      display: none;
    }
  }
}
.h1 {
  font-family: $base-font-family;
}
body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  color: $base-font-color;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    font-weight: normal;
    color: inherit;
  }

  strong {
    font-weight: $bold-font-weight;
  }

  em {
    font-style: italic;
  }

  #root,
  #__next {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    & > div:first-of-type {
      flex: 1;
    }
  }

  .container {
    width: $container-width;
    max-width: 100vw;
    margin: 0 auto;
    padding: 0 $spacer;

    @media (max-width: $medium-screen) {
      width: 100%;
    }
  }

  table {
    width: 100%;
    margin: $spacer * 3 0;
    font-size: $small-font-size;

    th {
      color: $gray-dark;
      text-align: left;
      padding: $spacer math.div($spacer, 2);
    }

    tr {
      color: $base-font-color;
      border-bottom: 1px solid $gray-light;
    }

    td {
      padding: $spacer math.div($spacer, 2);
      vertical-align: middle;

      img {
        vertical-align: middle;
        margin-right: $spacer;
      }
    }
  }

  .checkbox {
    position: relative;
    display: inline;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0.4rem;
      top: 0.05rem;
      width: 0.3rem;
      height: 0.6rem;
      border: solid $white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      z-index: 1;
    }

    input {
      display: none;

      &:checked ~ span {
        &:before {
          background-color: $black;
        }
      }
    }

    span {
      margin-left: $spacer * 2;

      &:before {
        background-color: $white;
        border-radius: 0.2rem;
        border: 1px solid $blue;
        content: "";
        display: block;
        height: 1rem;
        position: absolute;
        top: 0;
        width: 1rem;
      }
    }
  }
}

.form-error {
  color: $rose;
}

// https://github.com/rstacruz/nprogress/blob/master/nprogress.css
#nprogress .spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  right: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  z-index: 1031;
}

#nprogress .spinner-icon {
  width: 50px !important;
  height: 50px !important;
}
@media (max-width: 1280px) {
  .NavMenu_Desktop {
    display: none;
  }
}

// Styles for Google Maps Autocomplete API dropdown
.pac-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border: 1px solid lightgray;
  z-index: 1000000; // Value taken from constants `Z_INDEX_OVERLAY`

  &:after {
    content: none;
  }
}

.pac-item {
  padding: 0.5rem 1rem;
}
// End of Google Maps Autocomplete API dropdown styles
